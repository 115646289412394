import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { uid } from 'uid';

import {
    AddPortfolioListAction,
    ChangeCurrentPortfolioListAction,
    ClosePortfolioListAction,
} from '~/app/core/state/portfolios-list/portfolios-list.actions';
import {
    PortfoliosListState,
    PortfoliosListStateModel,
} from '~/app/core/state/portfolios-list/portfolios-list.state';
import { Workspace } from '~/app/shared/types/workspace.type';

import {
    AddWorkspaceAction,
    ChangeActiveWorkspaceAction,
    CloseWorkspaceAction,
    ResetPortfoliosWorkspaceAction,
} from './portfolios-workspace.actions';

export type PortfoliosWorkspaceStateModel = {
    currentWorkspace: string,
    workspaces: Record<string, Workspace>,
}

const defaults = {
    currentWorkspace: '',
    workspaces: {},
};

@State<PortfoliosWorkspaceStateModel>({
    name: 'portfoliosWorkspace',
    defaults,
})
@Injectable()
export class PortfoliosWorkspaceState implements NgxsOnInit {

    @Selector()
    static getWorkspaces(state: PortfoliosWorkspaceStateModel) {
        return Object.values(state.workspaces);
    }
    // TODO REFACTOR REGRESSION filtersCount
    // @Selector([PortfoliosWorkspaceState.getWorkspaces, PortfoliosListState.getLists])
    // static workspaces(workspaces: Record<string, Workspace>, listState: PortfoliosListStateModel) {
    //     return Object.values(workspaces ?? {})
    //         .map((item) => ({
    //             ...item,
    //             ...(listState?.lists[item.key] ? { number: listState.lists[item.key].filtersCount } : {}),
    //         }));
    // }

    @Selector()
    static currentWorkspace(state: PortfoliosWorkspaceStateModel) {
        return state.currentWorkspace;
    }

    @Selector()
    static workspacesCount(state: PortfoliosWorkspaceStateModel): number {
        return Object.keys(state.workspaces).length;
    }

    @Action(AddWorkspaceAction)
    addWorkspace({ getState, patchState, dispatch }: StateContext<PortfoliosWorkspaceStateModel>, action: AddWorkspaceAction) {
        const state = getState();
        const key: string = uid();
        const workspaceName$ = action.payload.label ? of(action.payload.label) : of('workspace.default');

        return workspaceName$.pipe(
            tap((label: string) => {
                patchState({
                    currentWorkspace: key,
                    workspaces: {
                        ...state.workspaces,
                        [key]: {
                            key,
                            label,
                            translatable: !!action.payload.label,
                        },
                    },
                });

                dispatch(new AddPortfolioListAction(key));
            }),
        );
    }

    @Action(ChangeActiveWorkspaceAction)
    changeActiveWorkspace({ getState, patchState, dispatch }: StateContext<PortfoliosWorkspaceStateModel>, action: ChangeActiveWorkspaceAction) {
        const state = getState();

        if (!state.workspaces[action.key]) { return; }

        patchState({ currentWorkspace: action.key });

        dispatch(new ChangeCurrentPortfolioListAction(action.key));
    }

    @Action(CloseWorkspaceAction)
    closeWorkspace({ getState, patchState, dispatch }: StateContext<PortfoliosWorkspaceStateModel>, action: CloseWorkspaceAction) {
        const state = getState();

        if (!state.workspaces[action.key]) { return; }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [action.key]: workspace, ...rest } = state.workspaces;

        let { currentWorkspace } = state;

        if (state.currentWorkspace === action.key) {
            const index = Object.keys(state.workspaces).indexOf(action.key);

            if (index === Object.keys(state.workspaces).length - 1) {
                currentWorkspace = Object.keys(state.workspaces)[Object.keys(state.workspaces).length - 2];
            } else {
                currentWorkspace = Object.keys(state.workspaces)[index + 1];
            }

            if (Object.keys(rest).length > 0) {
                dispatch(new ChangeActiveWorkspaceAction(currentWorkspace));
            }
        }

        patchState({ workspaces: { ...rest } });

        dispatch(new ClosePortfolioListAction(action.key));

        // Add new workspace
        if (Object.keys(rest).length === 0) {
            dispatch(new AddWorkspaceAction({ }));
        }
    }

    @Action(ResetPortfoliosWorkspaceAction)
    resetPortfoliosWorkspace({ getState, dispatch }: StateContext<PortfoliosWorkspaceStateModel>) {
        const state = getState();
        Object.keys(state.workspaces).forEach((key) => {
            dispatch(new CloseWorkspaceAction(key));
        });
    }

    ngxsOnInit({ getState, dispatch }: StateContext<PortfoliosWorkspaceStateModel>) {
        const state = getState();

        if (Object.keys(state.workspaces).length === 0) {
            dispatch(new AddWorkspaceAction({ }));
        }
    }
}
